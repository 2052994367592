

.display {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* .display input[type='text'] {
  font-size: 1.3rem;
  border: none;
  background-color: #f5f5f5;
  margin-bottom: 10px;
  text-align: right;
  padding-right: 10px;
  width: 100%;
  color: #333;
  outline: none;
} */

.output {
  font-size: 1rem;
  color: #333;
  text-align: right;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(4, 0.2fr);
  grid-gap: 0px;
}

.btn1 {
  font-size: 1.5rem;
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}


.btn-secondary {
  background-color: #eee;
  color: #333;
}

.btn-secondary:hover {
  background-color: #ddd;
}

.btn-light1 {
  background-color: #fff !important;
  color: #333;
}

.btn-light:hover {
  background-color: #eee;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-primary:hover {
  background-color: #0069d9;
}

.equal {
  grid-column: span 2;
}

.zero {
  grid-column: span 2;
}

/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

/* .modal-header {
  border-bottom-color: #EEEEEE;
  background-color: #FAFAFA;
} */

/* ----- v CAN BE DELETED v ----- */
body {
  background-color: #78909C;
}

.demo {
  padding-top: 60px;
  padding-bottom: 110px;
}

.btn-demo {
  margin: 15px;
  padding: 10px 15px;
  border-radius: 0;
  font-size: 16px;
  background-color: #FFFFFF;
}

.btn-demo:focus {
  outline: 0;
}

.demo-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px;
  background-color: #212121;
  text-align: center;
}

.demo-footer>a {
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}
.white-content {

  background: $light-bg;

  .navbar.navbar-transparent .navbar-brand {
    color: $black-states;
  }

  .navbar.navbar-transparent .navbar-toggler-bar {
    background: $black-states;
  }

  .navbar.navbar-transparent .navbar-nav li a:not(.dropdown-item) {
    color: $black-states;

    & i {
      color: $black-states;
    }
  }

  .navbar.navbar-transparent .navbar-minimize button i {
    color: $black-states;
  }

  .navbar.navbar-transparent .search-bar.input-group i {
    color: $black-states;
  }

  .navbar.navbar-transparent .search-bar.input-group .form-control {
    color: $default;

    &::placeholder {
      color: $dark-gray;
    }
  }

  .sidebar {
    box-shadow: 0 2px 22px 0 rgba(0, 0, 0, .1), 0 4px 20px 0 rgba(0, 0, 0, .15);

    p {
      color: $opacity-8;
    }
  }

  .main-panel {
    background: $light-bg;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol li,
  ul li,
  pre {
    color: $black-states;
  }

  .font-icon-detail i {
    color: $black;
  }

  .btn:not([data-action]):hover {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  }

  .btn-neutral.btn-link {
    color: rgba($black, 0.7);
  }

  //style for inputs

  @include form-control-placeholder(rgba($black, 0.4), 1);

  .has-danger {

    .form-control,
    .input-group-prepend .input-group-text {
      border-color: $danger-states;
    }
  }

  .input-group-prepend .input-group-text {
    border-color: rgba($black-states, 0.5);
    color: $black-states;
  }

  .form-control {
    color: $black;
    border-color: rgba($black-states, 0.5);

    &:focus {
      border-color: $primary;
    }
  }

  .form-group.no-border,
  .input-group.no-border {

    .form-control,
    .form-control+.input-group-prepend .input-group-text,
    .form-control+.input-group-append .input-group-text,
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      background-color: $opacity-gray-3;

      &:focus,
      &:active,
      &:active {
        background-color: $opacity-gray-5;
      }
    }

    .form-control {
      &:focus {

        &+.input-group-prepend .input-group-text,
        &+.input-group-append .input-group-text {

          background-color: $transparent-bg;
        }
      }
    }


  }

  .input-group[disabled] {

    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      background-color: $black;
    }
  }

  .form-control[disabled],
  .form-control[readonly],
  fieldset[disabled] .form-control {
    background: $light-gray;
    border-color: rgba($black-states, 0.3);
  }

  .input-group-focus {

    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text,
    .form-control {
      border-color: $primary;
    }

    &.no-border {

      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text {

        background-color: $opacity-gray-5;
      }
    }
  }

  .input-group-prepend .input-group-text {
    border-right: none;
  }

  .input-group-append .input-group-text {
    border-left: none;
  }

  .has-danger .form-control:focus,
  .has-success.input-group-focus .input-group-append .input-group-text,
  .has-success.input-group-focus .input-group-prepend .input-group-text {
    border-color: $danger-states;
  }

  .has-success .form-control:focus,
  .has-success.input-group-focus .input-group-append .input-group-text,
  .has-success.input-group-focus .input-group-prepend .input-group-text {
    border-color: darken($success, 10%);
  }

  .btn.btn-link {

    &:hover,
    &:active,
    &:focus {
      color: $dark-gray !important;
    }
  }

  .btn-group .btn.active {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  }

  .card:not(.card-white) {
    background: $white;
    box-shadow: 0 1px 15px 0 rgba(123, 123, 123, 0.05);

    .card-header {
      color: $black;

      a[data-toggle="collapse"] {
        color: $black;
      }
    }

    .card-header .card-title,
    .card-body .card-title {
      color: $black-states;
    }

    .card-body {

      .card-category,
      .card-description {
        color: $black-states;
      }
    }


    label:not(.btn) {
      color: $default;
    }


    &.nav-pills .nav-item .nav-link {
      color: $nav-gray;
      background-color: hsla(0, 0%, 87%, .3);

      &:not(.active):hover {
        background: hsla(0, 0%, 87%, .5);
      }

      &.active {
        color: $white;

      }
    }

    .tab-content .tab-pane {
      color: $nav-gray;
    }


    .card {
      box-shadow: none;
    }

    &.card-plain {
      background: $transparent-bg;
      box-shadow: none;
    }

    &.card-tasks {
      .card-body {
        i {
          color: rgba(34, 42, 66, 0.7);

          &:hover {
            color: $black;
          }
        }
      }
    }
  }

  .table {

    >tbody>tr>td {
      color: rgba($black, 0.7) !important;

    }

    >thead>tr>th,
    >tbody>tr>th,
    >tfoot>tr>th,
    >thead>tr>td,
    >tbody>tr>td,
    >tfoot>tr>td {
      border-color: rgba($black, 0.2);
      padding: 12px 7px;
      vertical-align: middle;
    }

    >thead>tr>th,
    button.btn-neutral.btn-link {
      color: rgba($black, 0.7);
    }
  }

  .footer ul li a {
    color: $black-states;
  }

  .footer .copyright {
    color: $black-states;
  }

  .progress-container,
  .progress-container.progress.sm {
    .progress {
      background: rgba($black, 0.1);
      box-shadow: 0 0 0 3px rgba($black, 0.1);

      .progress-value {
        color: $default;
      }
    }

    .progress-badge {
      color: $black-states;
    }
  }

  .full-page {
    background: $light-bg;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ol li,
    ul li,
    pre {
      color: $black-states;
    }

    .description {
      color: $dark-gray;
    }

    .footer ul li a {
      color: $black-states;
    }

    .footer .copyright {
      color: $black-states;
    }
  }

  .nav-pills .nav-item .nav-link:not(.active) {
    background: darken($light-bg, 10%);
    color: $black-states;

    &:hover {
      background: darken($white, 20%);

    }
  }

  tfoot th {
    color: black !important;
    /* Change the text color */
    background-color: white;
    /* Change the background color */
  }

  // View Money Market Security Show table in vertical form
  .transform {
    display: -ms-grid;
    -ms-grid-rows: auto auto;
    -ms-grid-columns: auto auto;
  }

  .transform thead {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
  }

  .transform tbody {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
  }

  .transform caption {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }

  // Everyone Else's Grid /
  @supports (display: grid) {
    .transform {
      display: grid;
      grid-template-columns: min-content min-content;
      grid-template-rows: auto auto;
      grid-template-areas:
        "caption caption"
        "head body";
    }

    .transform thead {
      grid-area: head;
    }

    .transform tbody {
      grid-area: body;
    }

    // .transform caption {
    //   grid-area: caption;
    // }
  }

  // Flex - Cross Browser CSS /
  .transform thead {
    display: flex;
    flex-shrink: 0;
    min-width: min-content;
  }

  .transform tbody {
    display: flex;
  }

  .transform tr {
    display: flex;
    flex-direction: column;
    min-width: min-content;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.7);
    flex-shrink: 0;
  }

  .transform th {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 500 !important;
    width: 180px;
    height: 40px;
    color: rgba(255, 255, 255, 0.7);
  }

  .transform td {
    width: 200px;
    height: 40px;
  }

  .transform td,
  .transform th {
    display: block;
    color: rgba(255, 255, 255, 0.7);
  }

  .transform caption {
    display: block;
  }

  // Visual styling /
  .transform {
    border-collapse: collapse;
  }

  .transform td {
    border-top: none !important;
  }

  .transform th {
    border-bottom: 1px solid rgba(65, 61, 61, 0.5) !important;
    border-right: 1px solid rgba(65, 61, 61, 0.5) !important;
    line-height: 16px;
  }

  .transform td {
    border-bottom: 1px solid rgba(65, 61, 61, 0.5) !important;
  }

  // end View Money Market Security Show table in vertical form

  // Start Calculator Fixed on Right Side
  .calculator {
    width: 250px;
    background-color: #ffffff;
    /* border: 1px solid #1E1E2F; */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin: 10 auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    /* max-height: 60vh; */
    /* overflow-y: auto;
   overflow-x: hidden; */
  }

  .calculator .output1 {
    color: black;
  }

  .scrollable1-div {
    overflow-y: scroll;
    height: 200px;
    /* set a fixed height for the div */
    // direction: rtl; /* set the text direction to right-to-left */
    display: flex;
    flex-direction: column;
    /* reverse the order of the content */
  }

  // end Calculator Fixed on Right Side

}